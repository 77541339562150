import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      model: {
        value: _vm.showLoginCard,
        callback: function ($$v) {
          _vm.showLoginCard = $$v
        },
        expression: "showLoginCard",
      },
    },
    [
      _c("Login", {
        attrs: { onlyGitLogin: true },
        on: {
          login: function ($event) {
            _vm.showLoginCard = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }