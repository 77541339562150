<template>
</template>

<script>
    import CommonStorageBase from "../../CommonStorageBase";

    export default {
        name: 'model-storage-base',
        mixins: [CommonStorageBase],
        created() {},
        computed:{

        }
    }


</script>